.vertical-timeline {
    
    &:before {
        background-color: $accent;
    }
}
.vertical-timeline {
    width: 100% !important;
    max-width: unset;
}

.vertical-timeline-element {
    @extend .my-5;

    .vertical-timeline-element-content {
        @extend .p-4;

        background-color: white;
        border-radius: 0;
        border: unset;
        @extend .shadow;

        .vertical-timeline-element-subtitle {
            @extend .small;
        }

        .vertical-timeline-element-title {
            color: $primary;
        }
        p{
            color: $dark;
            font-size: unset;
        }
    }

    .technologies {
        @extend .mt-4;
        @extend .p-0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
        gap: 10px;
        list-style: none;
        color: $primary;

        li {
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            svg {
                width: 30px;
                height: 30px;
            }

            span {
                margin-top: 5px;
                font-size: 14px;
                font-weight: bold;
            }

        }
    }

    .vertical-timeline-element-icon {
        box-shadow: 0;
        background-color: $primary;
        color: $light;
        box-shadow: 0 0 0 4px $light, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
    }
}