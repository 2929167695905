.project-card {
    a {
        img {
            @extend .img-fluid;
            object-fit: cover;
            @media screen and (max-width: 992px ) {
            }
        }
    }

    p {
        @extend .mb-0;

        .technologies {
            @extend .my-3;
            @extend .p-0;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
            gap: 10px;
            list-style: none;
            color: $primary;
            gap: 10px;


            li {
                padding: 5px;
                height: 60px;
                width: 60px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                align-self: center;
                justify-self: center;

                svg {
                    width: 30px;
                    height: 30px;
                }

                span {
                    font-size: 12px;
                    font-weight: bold;
                }

            }
        }
    }
}